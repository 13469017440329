import React from "react"
import styled from "styled-components"
import MembersSection from "./MembersSection"
import ToolsSection from "./ToolsSection"
import Container from "../components/Container"
import Header from "./BigHeader"
import SubHeader from "./SubHeader"
import P from "./P"

class ZodProblemSection extends React.Component {
  render() {
    return (
      <Container>
        <Header>The Problem</Header>
        <P>
          My brother and I noticed that many shop owners in Kuwait are starting
          to sell their products through auctions on Instagram live. The trend
          became obvious when many accounts from different countries also
          started doing the same thing. However, since Instagram live was not
          intended for live auctions. Many problems were evident:
        </P>
        <SubHeader>Main pain points</SubHeader>
        <ul>
          <li>
            20 seconds delay in the live stream resulting in a less interactive
            experience
          </li>
          <li>No place for showing the current bidding</li>
          <li>No place for showing the products’ details</li>
          <li>No built-in way for payment or payment method verification</li>
          <li>No built-in way to share contact and delivery info</li>
        </ul>
        <P>
          Although the list keeps on going, people kept selling through
          Instagram live which showed a definite need for a better solution.
        </P>
        <Header style={{ marginTop: 40 }}>Proposed Solution</Header>
        <P>
          As a result, my brother and I decided to start our own startup to
          solve all the problem above and more. We proposed creating a
          marketplace that solves the problems above and more. In sprit of
          creating a lean startup, we decided to build an app that serves as an
          MVP for both sellers and buyers to use.
        </P>
      </Container>
    )
  }
}

export default ZodProblemSection
