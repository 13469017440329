import React from "react"
import styled from "styled-components"
import Container from "../components/Container"

class ZodArchitectureHighlight extends React.Component {
  render() {
    return (
      <Container noTop dark>
        <HighlightContainer>
          <H4 white>Highlight</H4>
          <P>
            While working with Red5Pro, we noticed some bugs in their mobile
            SDK. After some discussions with their lead developer, they said
            that
            <b> “the problem is beyond their capabilities currently”</b>. Given
            that, I decided to give it a shot myself. With no experience with
            their codebase whatsoever and a lot of reverse engineering I managed
            to fix it in
            <b> two days</b>.
          </P>
        </HighlightContainer>
      </Container>
    )
  }
}

const HighlightContainer = styled.div`
  background-color: rgba(92, 119, 250, 0.8);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 40px;
`

const H4 = styled.h4`
  font-size: 24px;
  font-weight: bold;
  color: white;
`

const P = styled.p`
  font-size: 18px;
  line-height: 34px;
  color: white;
  margin-bottom: 0;
`

export default ZodArchitectureHighlight
