import React from "react"
import styled from "styled-components"
import ZodFrames from "./ZodFrames"
import "./IntroSection.css"
import { Link } from "gatsby"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleContainer = styled.div`
  background-color: #171566;
  min-width: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
`

const SubContainer = styled.div`
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: relative;
`

const FramesContainer = styled.div`
  padding-top: 10px;
  overflow: hidden;
  flex: 0 0 60%;
`

const H1 = styled.h2`
  color: #fff;
  font-size: 8vh;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 900;
`

const H2 = styled.h2`
  color: #fff;
`

const H3 = styled.h3`
  color: #fff;
`

const P = styled.p`
  font-size: 16px;
  line-height: normal;
  color: #fff;
`

const OverviewSection = styled.div`
  background-color: #242536;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 12%;
  padding-right: 12%;
`

const SubSection = styled.div`
  max-width: 35%;
  min-height: 230px;
  margin: 40px;
`

const BackP = styled.p`
  font-size: 2vh;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  top: 3%;
  left: 8%;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    border-bottom: 2px solid white;
  }

  &,
  &::after {
    transition: all 0.07s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`

class IntroSection extends React.Component {
  render() {
    return (
      <>
        <TitleContainer>
          <SubContainer>
            <Link to="/">
              <BackP>
                <img
                  style={{
                    height: "2.5vh",
                    width: "2.5vh",
                    marginBottom: "-0.5vh",
                  }}
                  src="/images/Back.svg"
                />
                &nbsp;Go Home
              </BackP>
            </Link>
            <H1>
              Zod
              <br /> Mobile App
            </H1>
            <H2>Project Case Study</H2>
            <div id="icon-scroll" />
          </SubContainer>
          <FramesContainer>
            <ZodFrames />
          </FramesContainer>
        </TitleContainer>
        <Container>
          <OverviewSection>
            <SubSection>
              <H3>Overview</H3>
              <P>
                Zod is a startup based in the Middle East. Its aim is to create
                a live auctions marketplace. We noticed that many people were
                organizing auctions through Instagram live. Since the platform
                itself was never intended for such a thing, it showed many
                challenges. As a result, we decided to start a company to solve
                all these pain points.
              </P>
            </SubSection>
            <SubSection>
              <H3>My Role</H3>
              <P>
                I was one of the founding engineers of the company. My
                responsibilities included hiring team members, project
                management, architecting the big picture, programming critical
                parts and talking to users for feedback. My day-to-day was
                leading the standup meeting, programming, code reviewing, and
                creating issues for the future.
              </P>
            </SubSection>
          </OverviewSection>
        </Container>
      </>
    )
  }
}

export default IntroSection
