import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px;
  padding-left: 10%;
  padding-right: 10%;
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Name = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
`
const Position = styled.p`
  font-size: 18px;
`
const ZodNotableTools = () => {
  const data = useStaticQuery(graphql`
    query {
      native: file(relativePath: { eq: "React-Native.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      typescript: file(relativePath: { eq: "typescript.png" }) {
        childImageSharp {
          fixed(width: 90, height: 90, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      redux: file(relativePath: { eq: "redux.png" }) {
        childImageSharp {
          fixed(width: 100, height: 90, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      firebase: file(relativePath: { eq: "React-Native-Firebase.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      styled: file(relativePath: { eq: "styled1.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      moti: file(relativePath: { eq: "moti.png" }) {
        childImageSharp {
          fixed(width: 120, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      nav: file(relativePath: { eq: "nav.png" }) {
        childImageSharp {
          fixed(width: 100, height: 102, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <div style={{ backgroundColor: "#171566" }}>
      <Container>
        <Member>
          <Img fixed={data.native.childImageSharp.fixed} />
          <Name>React Native</Name>
        </Member>
        <Member style={{ marginBottom: -20 }}>
          <Img fixed={data.typescript.childImageSharp.fixed} />
          <Name style={{ marginTop: 20 }}>Typescript</Name>
        </Member>
        <Member style={{ marginBottom: -20 }}>
          <Img fixed={data.redux.childImageSharp.fixed} />
          <Name style={{ marginTop: 20 }}>Redux Toolkit</Name>
        </Member>
      </Container>
      <Container>
        <Member>
          <Img fixed={data.firebase.childImageSharp.fixed} />
          <Name>React-Native-Firebase</Name>
        </Member>
        <Member>
          <Img fixed={data.nav.childImageSharp.fixed} />
          <Name>React Navigation</Name>
        </Member>
        <Member>
          <Img fixed={data.styled.childImageSharp.fixed} />
          <Name>Styled Components</Name>
        </Member>
        <Member style={{ marginBottom: -20 }}>
          <Img fixed={data.moti.childImageSharp.fixed} />
          <Name style={{ marginTop: 40 }}>Moti</Name>
        </Member>
      </Container>
    </div>
  )
}

export default ZodNotableTools
