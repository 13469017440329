import React from "react"
import styled from "styled-components"
import ZodMembersSection from "./ZodMembersSection"
import ToolsSection from "./ToolsSection"
import Container from "../components/Container"
import Header from "./BigHeader"
import SubHeader from "./SubHeader"
import P from "./P"

class ZodTeamSection extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Header>The Team</Header>
          <P>
            Being from a business background, my brother Hamad was appointed as
            CEO to manage all the project vision, relations and finances. I was
            appointed as a CTO since I had the technical knowledge. Saad Sawari
            who was a family friend joined as a part-time consultant (Worked for
            Intel for 7 years and now an independent consultant). After multiple
            discussions we decided to hire three more freelance software
            engineers to help us with building the app.
          </P>
          <SubHeader>Team Members</SubHeader>
        </Container>
        <ZodMembersSection />
      </>
    )
  }
}

export default ZodTeamSection
