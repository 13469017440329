import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "../components/Container"
import SubHeader from "../components/SubHeader"
import P from "../components/P"
import HomeVid from "../images/home.mp4"
import ExploreVid from "../images/explore.mp4"
import AuctionVid from "../images/auction.mp4"
import Auction2Vid from "../images/auction2.mp4"
import ProfilePrevVid from "../images/profilePrev.mp4"
import PreAucVid from "../images/preAuc.mp4"
import LiveVid from "../images/live.mp4"
import LiveProdVid from "../images/liveProd.mp4"

const HorizontalContainer = styled.div`
  min-width: 100vh;
  display: flex;
  justify-content: space-around;
  background-color: #171566;
  padding-top: 40px;
  padding-bottom: 40px;
`

const Desc = styled.h4`
  margin-top: 30px;
  font-size: 24px;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
`
const VerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const H4 = styled.h4`
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  color: #000;
`

const ZodFrontEndResult = () => {
  const data = useStaticQuery(graphql`
    query {
      splash: file(relativePath: { eq: "splash.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      welcome: file(relativePath: { eq: "welcome.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      home: file(relativePath: { eq: "home.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      explore: file(relativePath: { eq: "explore.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      profile: file(relativePath: { eq: "profile.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.welcome.childImageSharp.fixed} />
          <Desc>Welcome Screen</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.home.childImageSharp.fixed} />
          <Desc>Home Screen</Desc>
        </VerContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={HomeVid} type="video/mp4" />
          </video>
          <Desc>Home Screen (Scrolling)</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={ExploreVid} type="video/mp4" />
          </video>
          <Desc>Explore Screen (Categories)</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.profile.childImageSharp.fixed} />
          <Desc>Profile Screen</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={AuctionVid} type="video/mp4" />
          </video>
          <Desc>Making a Bid</Desc>
        </VerContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={Auction2Vid} type="video/mp4" />
          </video>
          <Desc>Contacting Seller</Desc>
        </VerContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={ProfilePrevVid} type="video/mp4" />
          </video>
          <Desc>Messaging Other Users</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={PreAucVid} type="video/mp4" />
          </video>
          <Desc>Making a Products List for Next Auction</Desc>
        </VerContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={LiveVid} type="video/mp4" />
          </video>
          <Desc>Starting a Live Auction</Desc>
        </VerContainer>
        <VerContainer>
          <video width="337" height="730" autoPlay loop muted playinline>
            <source src={LiveProdVid} type="video/mp4" />
          </video>
          <Desc>Managing Products During Auction</Desc>
        </VerContainer>
      </HorizontalContainer>
    </>
  )
}

export default ZodFrontEndResult
