import React from "react"
import Container from "../components/Container"
import Header from "./BigHeader"
import SubHeader from "./SubHeader"
import P from "./P"
import styled from "styled-components"

class ZodDeliverySection extends React.Component {
  render() {
    return (
      <Container>
        <Header>Delivery</Header>
        <P>
          After 10 months of designing, programming and testing, we managed to
          deploy the public version of the app on both the Apple App Store and
          the Google Play Store.
        </P>
        <Header style={{ marginTop: 40 }}>Lessons Learned</Header>
        <P>
          Although we managed to successfully finish building the initial
          version of the app, due to inability to raise funding for the project,
          our startup is on hold until further notice. As a result of that,
          Hamad has moved on to further ventures and I decided take the offer to
          go graduate school (with full-ride scholarship!).
          <br />
          <br /> Like what’s said in the tweet above, launching my own startup
          has given me so much experience beyond what I imagined, and as a
          result, I feel more capable and motived to give it another shot one
          day. <br />
          <br />
          Until then, I decided to take on software engineering jobs to expand
          my expertise and meet people who would help me in my future ventures.
          Can't wait to see what the future has to offer.
        </P>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <blockquote class="twitter-tweet">
            <p lang="en" dir="ltr">
              The best way to learn how to build a startup is to just launch a
              startup. There are no failures only lessons.
            </p>
            &mdash; Andrew Gazdecki (@agazdecki){" "}
            <a href="https://twitter.com/agazdecki/status/1460260422733352964?ref_src=twsrc%5Etfw">
              November 15, 2021
            </a>
          </blockquote>
        </div>
      </Container>
    )
  }
}

export default ZodDeliverySection
