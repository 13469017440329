import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "../components/Container"
import SubHeader from "../components/SubHeader"
import Header from "../components/BigHeader"
import P from "../components/WhiteP"
import ZodToolsAndServicesSection from "../components/ZodToolsAndServicesSection"
import ZodArchitectureHighlight from "../components/ZodArchitectureHighlight"

const HorizontalContainer = styled.div`
  min-width: 100vh;
  display: flex;
  justify-content: space-evenly;
  background-color: #242536;
  padding-bottom: 30px;
  padding-top: 40px;
`

const ZodArchitectureSection = () => {
  const data = useStaticQuery(graphql`
    query {
      diagram: file(relativePath: { eq: "diagram.png" }) {
        childImageSharp {
          fixed(width: 1503, height: 794, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Container noTop dark>
        <Header white style={{ marginTop: 40 }}>
          Architecture
        </Header>
        <SubHeader white>Rationale</SubHeader>
        <P white>
          Given my experience in React, React Native seemed like a natural
          solution for a front-end. When we looked for a backend solution, three
          things were crucial: latency (for both streaming and interactions),
          time-to-market and supporting SMS authentication, the Middle East’s
          method of choice to login. After looking through our options it came
          evident that we needed to use a micro-services architecture to handle
          each need, resulting in the choices below :
        </P>
        <SubHeader white>Tools and Services</SubHeader>
      </Container>
      <ZodToolsAndServicesSection />
      <Container noTop dark>
        <SubHeader white>Final Result</SubHeader>
      </Container>
      <HorizontalContainer>
        <Img fixed={data.diagram.childImageSharp.fixed} />
      </HorizontalContainer>
      <ZodArchitectureHighlight />
    </>
  )
}

export default ZodArchitectureSection
