import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ZodFrontEndHighlight = () => {
  const data = useStaticQuery(graphql`
    query {
      graph: file(relativePath: { eq: "graph.png" }) {
        childImageSharp {
          fixed(width: 467, height: 289, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      heat: file(relativePath: { eq: "heat.png" }) {
        childImageSharp {
          fixed(width: 801, height: 136, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container noTop dark>
      <HighlightContainer>
        <H4 white>Highlight</H4>
        <P>
          Among the 4 developers working on the project, I managed to be the
          <b> developer with the most story points achieved</b>. Among the
          screens above, I was responsible for the home screen, auction post’s
          details screen (with comments and direct messaging actions), products
          list screen and live streamer’s screen. I was also responsible for
          creating serverless functions to handle authentication and
          notifications
        </P>
      </HighlightContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.graph.childImageSharp.fixed} />
          <Desc>GitHub Contribution Stats</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.heat.childImageSharp.fixed} />
          <Desc style={{ marginTop: 100 }}>GitHub Commits Heat Map</Desc>
        </VerContainer>
      </HorizontalContainer>
    </Container>
  )
}

const Container = styled.div`
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.noTop ? 0 : "100px")};
  padding-left: 25%;
  padding-right: 25%;
  background-color: ${props => (props.dark ? "#171566" : "white")};
`

const HighlightContainer = styled.div`
  background-color: rgba(92, 119, 250, 0.8);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 40px;
`

const H4 = styled.h4`
  font-size: 24px;
  font-weight: bold;
  color: white;
`

const P = styled.p`
  font-size: 18px;
  line-height: 34px;
  color: white;
  margin-bottom: 0;
`

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #171566;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: flex-end;
`

const Desc = styled.h4`
  margin-top: 30px;
  font-size: 24px;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
`
const VerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5vw;
`

export default ZodFrontEndHighlight
