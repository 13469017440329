import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ZodIntroSection from "../components/ZodIntroSection"
import TeamSection from "../components/TeamSection"
import ZodProblemSection from "../components/ZodProblemSection"
import ZodTeamSection from "../components/ZodTeamSection"
import ZodDesignSection from "../components/ZodDesignSection"
import ZodArchitectureSection from "../components/ZodArchitectureSection"
import ZodFrontEndSection from "../components/ZodFrontEndSection"
import ZodDeliverySection from "../components/ZodDeliverySection"
import NeedFindingSection from "../components/NeedFindingSection"
import SolutionSection from "../components/SolutionSection"
import { Helmet } from "react-helmet"
import BuyScreen from "../components/BuyScreen"
import { Media } from "gatsby-plugin-fresnel"
import ParticlesBG from "../components/ParticlesBG"

const SecondPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mohammad Baqer</title>
      </Helmet>
      <Media lessThan="lg">
        <ParticlesBG black />
        <BuyScreen />
      </Media>
      <Media greaterThanOrEqual="lg">
        <ZodIntroSection />
        <ZodProblemSection />
        <ZodTeamSection />
        <ZodDesignSection />
        <ZodArchitectureSection />
        <ZodFrontEndSection />
        <ZodDeliverySection />
      </Media>
    </>
  )
}

export default SecondPage
