import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import SubHeader from "../components/SubHeader"

// const HorzContainer = styled.div`
//   display: flex;
//   justify-content: space-around;
//   padding-top: 30px;
//   padding-left: 10vw;
//   padding-right: 10vw;
//   background-color: #242536;
// `

// const Member = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `

// const Name = styled.p`
//   font-size: 20px;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   color: white;
// `
// const Task = styled.p`
//   font-size: 16px;
//   text-align: center;
//   color: white;
// `

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px;
  padding-left: 15%;
  padding-right: 15%;
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Name = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
`
const Task = styled.p`
  font-size: 16px;
  text-align: center;
  color: white;
  line-height: normal;
`

const ZodToolsAndServicesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      native: file(relativePath: { eq: "React-Native.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      firebase: file(relativePath: { eq: "Firebase.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      red: file(relativePath: { eq: "Red5Pro.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      twilio: file(relativePath: { eq: "Twilio.png" }) {
        childImageSharp {
          fixed(width: 101, height: 101, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      algolia: file(relativePath: { eq: "Algolia.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    // <HorzContainer>
    //   <Member>
    //     <Img fixed={data.native.childImageSharp.fixed} />
    //     <Name>React Native</Name>
    //     <Task>Front-End</Task>
    //   </Member>
    //   <Member>
    //     <Img fixed={data.firebase.childImageSharp.fixed} />
    //     <Name style={{ paddingTop: 10 }}>Firebase</Name>
    //     <Task>
    //       Managing Data,
    //       <br />
    //       Real-time Interactions,
    //       <br />
    //       Notifications
    //     </Task>
    //   </Member>
    //   <Member>
    //     <Img fixed={data.red.childImageSharp.fixed} />
    //     <Name>Red5Pro</Name>
    //     <Task>Live Streaming</Task>
    //   </Member>
    //   <Member style={{ marginTop: -25 }}>
    //     <Img fixed={data.twilio.childImageSharp.fixed} />
    //     <Name style={{ marginTop: -20 }}>Twilio</Name>
    //     <Task>
    //       SMS authentication
    //       <br />
    //       (Firebase’s didn’t work in the Middle East)
    //     </Task>
    //   </Member>
    //   <Member>
    //     <Img fixed={data.algolia.childImageSharp.fixed} />
    //     <Name>Algolia</Name>
    //     <Task>Search functionality</Task>
    //   </Member>
    // </HorzContainer>
    <div style={{ backgroundColor: "#242536" }}>
      <Container>
        <Member>
          <Img fixed={data.native.childImageSharp.fixed} />
          <Name>React Native</Name>
          <Task>Front-End</Task>
        </Member>
        <Member style={{ marginBottom: -50 }}>
          <Img fixed={data.firebase.childImageSharp.fixed} />
          <Name>Firebase</Name>
          <Task>
            Managing Data, <br />
            Real-time Interactions, <br />
            Notifications
          </Task>
        </Member>
      </Container>
      <Container>
        <Member>
          <Img fixed={data.red.childImageSharp.fixed} />
          <Name>Red5Pro</Name>
          <Task>Live Streaming</Task>
        </Member>
        <Member>
          <Img fixed={data.twilio.childImageSharp.fixed} />
          <Name>Twilio</Name>
          <Task>SMS authentication</Task>
        </Member>
        <Member>
          <Img fixed={data.algolia.childImageSharp.fixed} />
          <Name>Algolia</Name>
          <Task>Search Functionality</Task>
        </Member>
      </Container>
    </div>
  )
}

export default ZodToolsAndServicesSection
