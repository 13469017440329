import React from "react"

import ContextSection from "../components/ContextSection"
import WireframingSection from "../components/WireframingSection"
import PrototypingSection from "../components/PrototypingSection"
import ZodWireframingSection from "../components/ZodWireframingSection"
import Container from "../components/Container"
import Header from "../components/BigHeader"
import SubHeader from "./SubHeader"
import P from "./P"

class ZodDesignSection extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Header>Design</Header>
          <SubHeader>Mockups</SubHeader>
          <P>
            We started the project with making some makeups to demonstrate a
            rough idea of what we have in mind. Hamad (CEO) and I (CTO) made the
            mockups using pencil and paper.
          </P>
        </Container>
        <ZodWireframingSection />
      </>
    )
  }
}

export default ZodDesignSection
