import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px;
  padding-left: 10%;
  padding-right: 10%;
`

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Name = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000;
`
const Position = styled.p`
  font-size: 18px;
`
const ZodMembersSection = () => {
  const data = useStaticQuery(graphql`
    query {
      mohammad: file(relativePath: { eq: "Mohammad6.jpeg" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      hamad: file(relativePath: { eq: "hamad.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      saad: file(relativePath: { eq: "saad2.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      max: file(relativePath: { eq: "max.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      arif: file(relativePath: { eq: "arif.png" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Container>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.hamad.childImageSharp.fixed}
          />
          <Name>Hamad Baqer</Name>
          <Position>Founding Manager - CEO</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.mohammad.childImageSharp.fixed}
          />
          <Name>Mohammad Baqer</Name>
          <Position>Founding Engineer - CTO</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.saad.childImageSharp.fixed}
          />
          <Name>Saad Sawari</Name>
          <Position>Founding Engineer - Consultant</Position>
        </Member>
      </Container>
      <Container>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.placeholder.childImageSharp.fixed}
          />
          <Name>Teoh Chee</Name>
          <Position>Full-Stack Developer</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.max.childImageSharp.fixed}
          />
          <Name>Max Litvinov</Name>
          <Position>Full-Stack Developer</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.placeholder.childImageSharp.fixed}
          />
          <Name>Rajendra Singh Rana</Name>
          <Position>Full-Stack Developer</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.arif.childImageSharp.fixed}
          />
          <Name>Mohammed Arif</Name>
          <Position>Full-Stack Developer</Position>
        </Member>
        <Member>
          <Img
            style={{ borderRadius: "100%" }}
            fixed={data.placeholder.childImageSharp.fixed}
          />
          <Name>Usman Khalid</Name>
          <Position>UI/UX Designer</Position>
        </Member>
      </Container>
    </>
  )
}

export default ZodMembersSection
