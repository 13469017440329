import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import SubHeader from "../components/SubHeader"
import Header from "../components/BigHeader"
import P from "../components/WhiteP"
import ZodNotableTools from "../components/ZodNotableTools"
import ZodFrontEndResult from "../components/ZodFrontEndResult"
import ZodFrontEndHighlight from "../components/ZodFrontEndHighlight"

const Container = styled.div`
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.noTop ? 0 : "100px")};
  padding-left: 25%;
  padding-right: 25%;
  background-color: ${props => (props.alt ? "#171566" : "white")};
`

const ZodFrontEndSection = () => {
  return (
    <>
      <Container noTop alt>
        <Header white style={{ marginTop: 40 }}>
          Frontend
        </Header>
        <SubHeader white>Project</SubHeader>
        <P white>
          Given the nature of our architecture, the front-end became the most
          crucial part of the project. As a result, the majority of time spent
          for the project was spent on building and refining our app. Overall we
          became very satisfied with the result.
        </P>
        <SubHeader white>Notable Tools</SubHeader>
      </Container>
      <ZodNotableTools />
      <Container noTop alt>
        <SubHeader white>Final Result</SubHeader>
      </Container>
      <ZodFrontEndResult />
      <ZodFrontEndHighlight />
    </>
  )
}

export default ZodFrontEndSection
