import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "../components/Container"
import SubHeader from "../components/SubHeader"
import P from "../components/P"

const HorizontalContainer = styled.div`
  min-width: 100vh;
  display: flex;
  justify-content: space-around;
  background-color: #171566;
  padding-top: 40px;
  padding-bottom: 40px;
`

const Desc = styled.h4`
  margin-top: 30px;
  font-size: 24px;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
`
const VerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const H4 = styled.h4`
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  color: #000;
`

const ZodWireframingSection = () => {
  const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "ZodHomeV1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      explore2: file(relativePath: { eq: "ZodExplore2V1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      newPost: file(relativePath: { eq: "ZodNewPostV1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      newPost2: file(relativePath: { eq: "ZodNewPost2V1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      newPost3: file(relativePath: { eq: "ZodNewPost3V1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      auction: file(relativePath: { eq: "ZodAuctionPostV1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      auction2: file(relativePath: { eq: "ZodAuctionPost2V1.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      homeV4: file(relativePath: { eq: "ZodHomeV4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      auctionV4: file(relativePath: { eq: "ZodAuctionInfo1V4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      auction2V4: file(relativePath: { eq: "ZodAuctionInfo2V4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      auction3V4: file(relativePath: { eq: "ZodAuctionInfo3V4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      live: file(relativePath: { eq: "ZodLiveStream1V4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      live2: file(relativePath: { eq: "ZodLiveStream2V4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      profile: file(relativePath: { eq: "ZodProfileV4.png" }) {
        childImageSharp {
          fixed(width: 337, height: 730, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Container noTop>
        <SubHeader>Prototypes</SubHeader>
        <P>
          After that, we hired Usman, our UI/UX designer, to start working on a
          hight-feidllity prototype of the mockups. Given my{" "}
          <Link to="/DegreePlannerCaseStudy">previous experience</Link> in
          UI/UX, I was able to work with Usman on the prototypes. Usually the
          process would start with Usman designing a screen in Figma and I would
          go in and make any changes to the files and point out on how to
          improve on the design. We kept iterating until we found something we
          are satisfied with.
        </P>
        <H4>Prototypes V1</H4>
      </Container>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.home.childImageSharp.fixed} />
          <Desc>Home</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.explore2.childImageSharp.fixed} />
          <Desc>Explore (Grid View)</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.newPost.childImageSharp.fixed} />
          <Desc>New Post</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.newPost2.childImageSharp.fixed} />
          <Desc>New Post</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.newPost3.childImageSharp.fixed} />
          <Desc>New Post</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.auction.childImageSharp.fixed} />
          <Desc>Auction Post</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.auction2.childImageSharp.fixed} />
          <Desc>Auction Post (Scrolled Down)</Desc>
        </VerContainer>
      </HorizontalContainer>
      <Container noTop>
        <H4>Evaluation</H4>
        <P>
          After making the prototypes, we started interviewing customers to
          receive some feedback on our designs, after that, we would do the
          required changes. Then, we would do the process again until we are
          ready to move on to the next phase. Based on this feedback we were
          able to make the following changes :
        </P>
        <H4>Prototypes V4</H4>
      </Container>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.homeV4.childImageSharp.fixed} />
          <Desc>Home</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.auctionV4.childImageSharp.fixed} />
          <Desc>Auction Post</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.auction2V4.childImageSharp.fixed} />
          <Desc>Auction Post (Scrolled Down)</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.auction3V4.childImageSharp.fixed} />
          <Desc>User Profile Preview</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.live.childImageSharp.fixed} />
          <Desc>Live Auction</Desc>
        </VerContainer>
        <VerContainer>
          <Img fixed={data.live2.childImageSharp.fixed} />
          <Desc>Live Auction (Products Details)</Desc>
        </VerContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <VerContainer>
          <Img fixed={data.profile.childImageSharp.fixed} />
          <Desc>Profile</Desc>
        </VerContainer>
      </HorizontalContainer>
    </>
  )
}

export default ZodWireframingSection
